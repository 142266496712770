// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from '@rails/activestorage';
import Rails from "@rails/ujs"
import 'bootstrap';
import 'chartkick/highcharts';
import jquery from 'jquery';
import jstz from 'jstz';
import Turbolinks from 'turbolinks';
import '../account/index.js';
import '../clean_admin/index.js';
import '../channels/index.js';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Make jQuery available as a global for inline scripts in views.
globalThis.$ = jquery;

// For inline use in `app/views/account/onboarding/user_details/edit.html.erb`.
globalThis.jstz = jstz;

Rails.start();
Turbolinks.start();
ActiveStorage.start();
