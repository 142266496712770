/*
 * Buttons that clear the value of the input(s) they are grouped with.
 * Mainly used with date/datetime inputs.
 */

function clearInputs(event) {
  event.preventDefault();

  const inputs = event.target.closest('fieldset, .form-group')?.querySelectorAll('input') ?? [];
  for (const input of inputs) {
    input.value = '';
  }
}

function enableClearButtons(scope) {
  for (const button of scope.querySelectorAll('button.clear-inputs')) {
    button.addEventListener('click', clearInputs);
  }
}

document.addEventListener('turbolinks:load', () => enableClearButtons(document.body));
