/*
Main javascript functions to init most of the elements

FIXME: Massive sections of this file have been commented out or removed over
time. This whole file needs some TLC.
*/

import { os_init_sub_menus } from './menus.js';

$(document).on('turbolinks:load', function() {

  // #11. MENU RELATED STUFF

  // INIT MOBILE MENU TRIGGER BUTTON
  $('.mobile-menu-trigger').on('click', function(){
    $('.menu-mobile .menu-and-user').slideToggle(200, 'swing');
    return false;
  });

  os_init_sub_menus();

});

$(document).on('turbolinks:load', function() {

  // #12. CONTENT SIDE PANEL TOGGLER

  $('.content-panel-toggler, .content-panel-close, .content-panel-open').on('click', function(){
    $('.all-wrapper').toggleClass('content-panel-active');
  });

});

$(document).on('turbolinks:load', function() {

  // #17. BOOTSTRAP RELATED JS ACTIVATIONS

  // - Activate tooltips
  $('[data-bs-toggle="tooltip"]').tooltip();

  // - Activate popovers
  $('[data-bs-toggle="popover"]').popover();

});
